<template>
  <div>
    <!-- Modal -->
    <div
      id="modal_aside_right"
      class="modal fixed-left fade"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-aside" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h5 class="modal-title text-white">Movimientos Bonos</h5>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group col-md-12">
              <label for="origen">Sitio Origen</label>
              <v-select
                class="form-control form-control-sm shadow-sm p-0"
                v-model="sitioOrigen"
                placeholder="Sitio Origen"
                label="nombre"
                :options="listasForms.sitiosOrigenes"
                :filterable="false"
                @search="buscarSitioOrigen"
                @input="selectSitioOrigen()"
              ></v-select>
            </div>
            <div class="form-group col-md-12">
              <label for="destino">Sitio Destino</label>
              <v-select
                class="form-control form-control-sm shadow-sm p-0"
                v-model="sitioDestino"
                placeholder="Sitio Destino"
                label="nombre"
                :options="listasForms.sitiosDestinos"
                :filterable="false"
                @search="buscarSitioDestino"
                @input="selectSitioDestino()"
              ></v-select>
            </div>
            <div class="row">
              <div class="col-md-7"></div>
              <div class="col-md-5">
                <div class="form-group">
                  <label class="form__label" for="hasDesc"
                    >Bono unitario?</label
                  >
                  <div class="float-right pr-2">
                    <input
                      id="hasDesc"
                      type="checkbox"
                      class="shadow"
                      v-model="hasDescription"
                      @click="cambiarBonoUnitario"
                    />
                    <label for="hasDesc">
                      <div class="toggle__switch"></div>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-12" v-if="!hasDescription">
              <div class="form-group">
                <label for="cantidad_bonos">Cantidad de Bonos</label>
                <input
                  type="number"
                  class="form-control form-control-sm shadow-sm"
                  id="cantidad_bonos"
                  placeholder="Cantidad Bonos"
                  v-model="form.cantidad_bonos"
                  @input="limpiarBonos"
                  :class="
                    $v.form.cantidad_bonos.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
            </div>
            <div class="col-lg-12" v-if="hasDescription">
              <div class="form-group">
                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="bono_unitario"
                  placeholder="Bono Unitario"
                  v-model.trim="form.bono_unitario"
                  @input="limpiarBonos"
                  :class="
                    $v.form.bono_unitario.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <button
                  type="button"
                  class="btn bg-gradient-info shadow-sm"
                  style="cursor: pointer"
                  @click="bonosOrigen"
                  v-show="!$v.form.$invalid"
                >
                  Buscar Bonos
                  <i class="fab fa-searchengin"></i>
                </button>
              </div>
            </div>
            <div class="row pt-2" v-if="bonos.length > 0">
              <div class="col-md-12">
                <div class="card-body p-0">
                  <!-- @change="findFilterBar($event)" -->
                  <table
                    class="
                      table table-bordered table-striped table-hover table-sm shadow 
                    "
                  >
                    <tbody class="alert-default-warning">
                      <tr v-for="item in bonos" :key="item.id">
                        <td>
                          <b>Código</b>
                          <b class="float-right"> {{ item.codigo }}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal-footer alert-default-secondary p-1 d-flex justify-content-start"
          >
            <button
              v-if="bonos.length > 0"
              type="button"
              v-show="!$v.form.$invalid"
              class="btn bg-gradient-primary shadow"
              @click="trasladoBonos()"
            >
              Trasladar Bonos
            </button>
            <button
              type="button"
              class="btn bg-gradient-secondary shadow"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
      <!-- modal-bialog .// -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "BonoMovimiento",
  components: {
    vSelect,
  },
  data() {
    return {
      form: {
        sitio_origen_id: null,
        sitio_destino_id: null,
        sitio_origen: null,
        sitio_destino: null,
        bloque_sitio_origen_id: null,
        bloque_sitio_destino_id: null,
        bono_unitario: null,
      },
      filtros: {
        sitio_origen_id: null,
        bono_unitario: null,
      },
      arraySellos: [],
      sitioOrigen: [],
      sitioDestino: [],
      listasForms: {
        sitiosOrigenes: [],
        sitiosDestinos: [],
      },
      bonos: {},
      hasDescription: false,
    };
  },
  validations() {
    if (!this.hasDescription) {
      return {
        form: {
          sitio_origen_id: {
            required,
          },
          sitio_destino_id: {
            required,
          },
          cantidad_bonos: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          sitio_origen_id: {
            required,
          },
          sitio_destino_id: {
            required,
          },
          bono_unitario: {
            required,
          },
        },
      };
    }
  },
  methods: {
    trasladoBonos() {
      this.form.bonos = this.bonos;
      if (!this.$v.form.$invalid) {
        axios({
          method: "POST",
          url: "/api/hidrocarburos/bonos/saveTraslado",
          data: this.form,
        })
          .then((response) => {
            this.form = {};
            this.bonos = {};
            this.filtros = {};
            this.sitioOrigen = [];
            this.sitioDestino = [];
            this.$parent.getIndex();
            this.$refs.closeModal.click();
            this.$swal({
              icon: "warning",
              title: "El traslado se efectuo exitosamente",
              text: "",
              showConfirmButton: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    bonosOrigen() {
      this.bonos = {};
      this.filtros.sitio_origen_id = null;
      if (this.sitioOrigen) {
        this.filtros.sitio_origen_id = this.sitioOrigen.id;
        this.filtros.cantidad_bonos = this.form.cantidad_bonos;
        this.filtros.bono_unitario = this.form.bono_unitario;
      }
      axios
        .get("/api/hidrocarburos/bonos/bonosMover", {
          params: this.filtros,
        })
        .then((response) => {
          this.bonos = response.data;
          if (this.bonos.mensaje) {
            this.$swal({
              icon: "error",
              title: this.bonos.mensaje,
              toast: true,
              position: "top-start",
              showConfirmButton: false,
              timer: 8000,
              timerProgressBar: true,
            });
          }
        });
    },

    async buscarSitioOrigen(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        let url = "api/admin/sitios/lista?sitio=" + search;
        await axios
          .get(url)
          .then(async function(response) {
            let respuesta = await response.data;
            me.listasForms.sitiosOrigenes = respuesta;
            //me.form.sitio_origen_id = me.listasForms.sitiosOrigenes.id;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async buscarSitioDestino(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        let url = "api/admin/sitios/lista?sitio=" + search;
        await axios
          .get(url)
          .then(async function(response) {
            let respuesta = await response.data;
            me.listasForms.sitiosDestinos = respuesta;
            //me.form.sitio_destino_id = me.listasForms.sitiosDestinos.id;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectSitioOrigen() {
      this.bonos = {};
      this.form.sitio_origen_id = "";
      this.form.sitio_origen = "";
      this.form.bloque_sitio_origen_id = "";
      if (this.sitioOrigen) {
        this.form.sitio_origen_id = this.sitioOrigen.id;
        this.form.sitio_origen = this.sitioOrigen.nombre;
        this.form.bloque_sitio_origen_id = this.sitioOrigen.bloque_id;
      } else {
        this.form.sitio_origen_id = null;
        this.form.sitio_origen = null;
      }
    },

    selectSitioDestino() {
      this.form.sitio_destino_id = "";
      this.form.sitio_destino = "";
      this.form.bloque_sitio_destino_id = "";
      if (this.sitioDestino) {
        this.form.sitio_destino_id = this.sitioDestino.id;
        this.form.sitio_destino = this.sitioDestino.nombre;
        this.form.bloque_sitio_destino_id = this.sitioDestino.bloque_id;
      } else {
        this.form.sitio_destino_id = null;
        this.form.sitio_destino = null;
      }
    },

    limpiarBonos() {
      this.bonos = {};
    },

    cambiarBonoUnitario() {
      this.bonos = {};
      this.form.bono_unitario = null;
      this.form.cantidad_bonos = null;
    },

    limpiarModal() {
      this.bonos = {};
      this.form = {
        sitio_origen_id: null,
        sitio_destino_id: null,
        sitio_origen: null,
        sitio_destino: null,
        bloque_sitio_origen_id: null,
        bloque_sitio_destino_id: null,
        bono_unitario: null,
      };
      this.sitioOrigen = {};
      this.sitioDestino = {};
      this.filtros = {
        sitio_origen_id: null,
        bono_unitario: null,
      };
    },
  },
};
</script>
<style>
.modal .modal-dialog-aside {
  width: 300px;
  max-width: 80%;
  height: 100%;
  margin: 0;
  transform: translate(2);
  transition: transform 0.2s;
}
.modal .modal-dialog-aside .modal-content {
  height: inherit;
  border: 1;
  border-radius: 1;
}
.modal .modal-dialog-aside .modal-content .modal-body {
  overflow-y: auto;
}
.modal.fixed-left .modal-dialog-aside {
  margin-left: auto;
  transform: translateX(100%);
}
.modal.fixed-right .modal-dialog-aside {
  margin-right: auto;
  transform: translateX(-100%);
}
.modal.show .modal-dialog-aside {
  transform: translateX(0);
}
</style>
